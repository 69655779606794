import { CircularProgress, styled } from '@mui/joy';

export const DelayedCircularProgress = styled(CircularProgress)`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
`;
