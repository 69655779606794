import { useCallback, useRef } from 'react';

type CallbackFunction<T extends unknown[]> = (...args: T) => void;

export function useSkippableCallback() {
  const skipNextRef = useRef(false);

  const skipNext = () => (skipNextRef.current = true);

  return useCallback(<T extends unknown[]>(callbackFactory: (skipNext: () => void) => CallbackFunction<T>) => {
    const callback = callbackFactory(skipNext);
    return (...args: T) => {
      if (skipNextRef.current) {
        skipNextRef.current = false;
        return;
      }
      return callback(...args);
    };
  }, []);
}
