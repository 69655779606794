import { format, isValid } from 'date-fns';
import { Segment, Trip } from '../../../../api-client-gen';

export const getNonWalkTripData = (trip: Trip) => {
  const segments = trip.segments.filter((segment) => segment.type !== 'WALK_TRANSFER').map(transformSegment);
  const totalTime = segments.map((segment) => segment.duration).reduce((a, b) => a + b);

  return {
    segments,
    totalTime,
  };
};

export const getTotalTimeWithWalking = (trip: Trip) => {
  const departureTimeWithWalking = getValidDate(trip.segments[0].stops[0].departureDateTime);
  const arrivalTimeWithWalking = getValidDate(trip.segments.at(-1)?.stops?.at(-1)?.arrivalDateTime);
  if (departureTimeWithWalking && arrivalTimeWithWalking) {
    return getDurationString(departureTimeWithWalking, arrivalTimeWithWalking);
  }
  return '';
};

const transformSegment = (segment: Segment) => {
  const departureDateTime = getValidDate(segment.stops[0].departureDateTime);
  const arrivalDateTime = getValidDate(segment.stops.at(-1)?.arrivalDateTime);
  return {
    departureTime: departureDateTime && format(departureDateTime, 'HH:mm'),
    arrivalTime: arrivalDateTime && format(arrivalDateTime, 'HH:mm'),
    vehicle: segment.transportProduct?.name ?? '?',
    vehicleShort: segment.transportProduct
      ? [segment.transportProduct.categoryShortForm, segment.transportProduct.line].filter((i) => i).join(' ')
      : '?',
    origin: segment.stops[0].name,
    originPlatform: segment.stops[0].departureTrack,
    destination: segment.stops[segment.stops.length - 1].name,
    destinationPlatform: segment.stops[segment.stops.length - 1].arrivalTrack,
    direction: segment.direction,
    duration: departureDateTime && arrivalDateTime ? arrivalDateTime.getTime() - departureDateTime.getTime() : 1000000,
    stops: segment.stops,
  };
};

export const getDurationString = (from?: Date, to?: Date, short = false) => {
  if (!from || !isValid(from) || !to || !isValid(to)) {
    return '';
  }
  const durationSeconds = (to.getTime() - from.getTime()) / 1000;
  const minutes = (durationSeconds / 60) % 60;
  const hours = Math.floor(durationSeconds / 60 / 60);
  return `${hours ? `${hours} h ` : ''}${minutes}${short ? '′' : ' min'}`;
};

const getValidDate = (dateString?: string) => {
  if (dateString) {
    const date = new Date(dateString);
    if (isValid(date)) {
      return date;
    }
  }
};
