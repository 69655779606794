import { Alert, CircularProgress, styled, Typography } from '@mui/joy';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { createContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApiError, TenantDefinition } from '../api-client-gen';
import { DelayedCircularProgress } from '../components/DelayedCircularProgress';
import { LoginCard } from '../components/services/SBBService/cards/LoginCard';
import { chooseTripRoute } from '../components/services/SBBService/SBBService';
import { Services } from '../components/services/Services';
import useRequiredParams from '../hooks/useRequiredParams';
import {
  getDecryptedServiceOfferingQuery,
  getServiceOfferingQuery,
  getServiceOfferingQueryKey,
} from '../state/api/serviceOffering';
import { getTenantQuery } from '../state/api/tenant';

const nullTenant = {
  id: '',
  key: '',
  displayName: '',
  sbbLocationName: '',
  logo: '',
  optionalReturnTicket: false,
  allowedCantons: [],
};
export const TenantContext = createContext<TenantDefinition>(nullTenant);

export default function ServiceOffering() {
  const navigate = useNavigate();
  const { tenantKey, serviceOfferingKey } = useRequiredParams();
  const key = useKey();
  const [password, setPassword] = useState(getCachedPassword() ?? '');
  const tryToLoginUsingCache = !!getCachedPassword();
  const queryClient = useQueryClient();

  const [tenantQuery, serviceOfferingBasicQuery, serviceOfferingQuery] = useQueries({
    queries: [
      getTenantQuery(tenantKey),
      getServiceOfferingQuery({ tenantKey, serviceOfferingKey }),
      {
        ...getDecryptedServiceOfferingQuery({
          tenantKey,
          serviceOfferingKey,
          key,
          password,
        }),
        enabled: !!password,
      },
    ],
  });

  useEffect(() => {
    if (serviceOfferingQuery.isSuccess) {
      if (!tryToLoginUsingCache) {
        cachePassword(password);
      }
      navigate(chooseTripRoute);
    }
  }, [serviceOfferingQuery.isSuccess]);

  if (!key) {
    console.error("The link didn't have a key.");
    return (
      <Wrapper>
        {tenantQuery.data && <Logo src={`data:image/svg+xml;base64,${tenantQuery.data.logo}`} />}
        <Alert color="danger">
          Den Link, den Sie eingegeben haben, scheint leider nicht korrekt zu sein. Bitte versuchen Sie es noch einmals.
        </Alert>
      </Wrapper>
    );
  }

  if (
    serviceOfferingBasicQuery.isError &&
    serviceOfferingBasicQuery.error instanceof ApiError &&
    serviceOfferingBasicQuery.error.status === 404
  ) {
    return (
      <Wrapper>
        {tenantQuery.data && <Logo src={`data:image/svg+xml;base64,${tenantQuery.data.logo}`} />}
        <Alert color="danger">Leider konnte Ihr Service-Angebot nicht gefunden werden.</Alert>
      </Wrapper>
    );
  }

  if (tenantQuery.isError || serviceOfferingBasicQuery.isError) {
    return (
      <Wrapper>
        {tenantQuery.data && <Logo src={`data:image/svg+xml;base64,${tenantQuery.data.logo}`} />}
        <Alert color="danger">
          Ihre Service-Angebote konnten leider momentan nicht geladen werden. Bitte versuchen Sie es später noch
          einmals.
        </Alert>
      </Wrapper>
    );
  }

  if (tenantQuery.isPending || serviceOfferingBasicQuery.isPending) {
    return (
      <Wrapper>
        <CircularProgress />
        <Typography>Ihre Service-Angebote werden geladen...</Typography>
      </Wrapper>
    );
  }

  const tenant = tenantQuery.data;
  const serviceOfferingBasic = serviceOfferingBasicQuery.data;
  const serviceOffering = serviceOfferingQuery.data;

  if (serviceOfferingQuery.isError) {
    if (password) {
      setPassword('');
    }
    if (tryToLoginUsingCache) {
      // when the cache login failed we reset the cache and query
      resetCachedPassword();
      void Promise.resolve().then(() =>
        queryClient.resetQueries({ queryKey: getServiceOfferingQueryKey(tenantKey, serviceOfferingKey) }),
      );
    }
  }

  return (
    <Wrapper>
      <Logo src={`data:image/svg+xml;base64,${tenant.logo}`} />
      <TenantContext.Provider value={tenant}>
        <AnimatePresence mode="popLayout">
          {serviceOffering ? (
            <Services key="service-offering" serviceOffering={serviceOffering} />
          ) : tryToLoginUsingCache ? (
            <DelayedCircularProgress size="sm" />
          ) : (
            <LoginCard
              serviceOfferingBasic={serviceOfferingBasic}
              onLogin={(password) => setPassword(password)}
              loginIsError={serviceOfferingQuery.isError}
              loginIsFetching={serviceOfferingQuery.isFetching}
            />
          )}
        </AnimatePresence>
      </TenantContext.Provider>
    </Wrapper>
  );
}

const cachedPasswordStorageKey = 'mercury-password';
const getCachedPassword = () => sessionStorage.getItem(cachedPasswordStorageKey);
const resetCachedPassword = () => sessionStorage.removeItem(cachedPasswordStorageKey);
const cachePassword = (password: string) => sessionStorage.setItem(cachedPasswordStorageKey, password);

function useKey() {
  const [searchParams, setSearchParams] = useSearchParams();

  const cachedKeyStorageKey = 'mercury-key';
  const searchParamKey = searchParams.get('key');

  useEffect(() => {
    if (searchParamKey) {
      sessionStorage.setItem(cachedKeyStorageKey, searchParamKey);
      setSearchParams(
        (searchParams) => {
          searchParams.delete('key');
          return searchParams;
        },
        { replace: true },
      );
    }
  }, [searchParamKey, searchParams]);

  return searchParamKey ?? sessionStorage.getItem(cachedKeyStorageKey);
}

const Wrapper = styled('div')`
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  gap: 2rem;
  padding: 1rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 2rem;
  }
`;

const Logo = styled('img')`
  max-width: 175px;
  max-height: 66px;
  display: block;
`;
