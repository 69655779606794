import { Download } from '@mui/icons-material';
import { Button, styled } from '@mui/joy';
import { useContext } from 'react';
import { TenantContext } from '../../../pages/ServiceOffering';
import { sbbColors } from './utils/sbbColors';

export default function DownloadTicketButton({ serviceKey, ticketLink }: { serviceKey: string; ticketLink?: string }) {
  const { key: tenantKey } = useContext(TenantContext);

  return (
    <StyledButton
      size="lg"
      component="a"
      startDecorator={<Download />}
      href={`/mercury-api/${tenantKey}/services/${serviceKey}/sbb/retrieve-ticket?ticketUrl=${ticketLink}`}>
      Ticket herunterladen
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  ${sbbColors}
` as typeof Button;
