import { ServiceOfferingResourceService } from '../../api-client-gen';
import { withCancellationSupport } from './utils/withCancellationSupport';

export const getServiceOfferingBasicQueryKey = (tenantKey: string, serviceOfferingKey: string) => [
  tenantKey,
  'service-offering-basic',
  serviceOfferingKey,
];

export const getServiceOfferingQuery = ({
  tenantKey,
  serviceOfferingKey,
}: {
  tenantKey: string;
  serviceOfferingKey: string;
}) => ({
  queryKey: getServiceOfferingBasicQueryKey(tenantKey, serviceOfferingKey),
  queryFn: withCancellationSupport(() =>
    ServiceOfferingResourceService.getServiceOffering(tenantKey, serviceOfferingKey),
  ),
});

export const getServiceOfferingQueryKey = (tenantKey: string, serviceOfferingKey: string) => [
  tenantKey,
  'service-offering',
  serviceOfferingKey,
];

export const getDecryptedServiceOfferingQuery = ({
  tenantKey,
  serviceOfferingKey,
  password,
  key,
}: {
  tenantKey: string;
  serviceOfferingKey: string;
  key: string | null;
  password: string | null;
}) => ({
  queryKey: getServiceOfferingQueryKey(tenantKey, serviceOfferingKey),
  queryFn: withCancellationSupport(() =>
    ServiceOfferingResourceService.getDecryptedServiceOffering(
      tenantKey,
      serviceOfferingKey,
      password ?? '',
      key ?? '',
    ),
  ),
  retry: false,
});
