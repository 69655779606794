import { Card, Divider, styled } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { VersionResourceService } from '../api-client-gen';

const FRONTEND_VERSION =
  typeof import.meta.env.VITE_FRONTEND_VERSION === 'string'
    ? import.meta.env.VITE_FRONTEND_VERSION
    : String(import.meta.env.VITE_FRONTEND_VERSION);

export function Version() {
  const {
    data: backendVersion,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['version'],
    queryFn: () => VersionResourceService.getVersion(),
    retry: false,
  });

  return (
    <Container>
      <Card>
        <pre>MERCURY</pre>
        <Divider />
        <pre>
          Frontend Version:&nbsp;{FRONTEND_VERSION}
          <br />
          Backend Version:&nbsp;&nbsp;
          {isLoading ? <Loading>loads</Loading> : error ? <Errored>error</Errored> : backendVersion}
        </pre>
      </Card>
    </Container>
  );
}

const Container = styled('div')`
  display: grid;
  height: 100vh;
  place-content: center;
  pre {
    margin: 0;
  }
`;

const Loading = styled('span')`
  color: var(--joy-palette-neutral-200);
`;

const Errored = styled('span')`
  color: var(--joy-palette-danger-500);
`;
