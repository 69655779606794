import { QueryFunction, QueryFunctionContext, QueryKey } from '@tanstack/react-query';
import { CancelablePromise } from '../../../api-client-gen';

/**
 * Add tanstack-query cancellation support to openapi generated requests.
 */
export function withCancellationSupport<T, TQueryKey extends QueryKey = QueryKey, TPageParam = never>(
  queryFunction: (context: QueryFunctionContext<TQueryKey, TPageParam>) => CancelablePromise<T>,
): QueryFunction<T, TQueryKey, TPageParam> {
  return (context) => {
    const queryResult: CancelablePromise<T> = queryFunction(context);
    context.signal?.addEventListener('abort', () => {
      queryResult.cancel();
    });
    return queryResult;
  };
}
