import {
  Alert,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  styled,
  Typography,
} from '@mui/joy';
import { m } from 'framer-motion';
import { forwardRef, useContext, useState } from 'react';
import { BasicServiceOffering } from '../../../../api-client-gen';
import { TenantContext } from '../../../../pages/ServiceOffering';
import { pageTransitionAnimationProps } from '../../../../utils/pageTransitionAnimationProps';
import { ServiceList } from '../../../ServiceList';
import DownloadTicketButton from '../DownloadTicketButton';

export const LoginCard = forwardRef<
  HTMLDivElement,
  {
    serviceOfferingBasic: BasicServiceOffering;
    onLogin: (password: string) => void;
    loginIsError: boolean;
    loginIsFetching: boolean;
  }
>(function LoginCard({ serviceOfferingBasic, onLogin, loginIsError, loginIsFetching }, ref) {
  const tenant = useContext(TenantContext);
  const [password, setPassword] = useState('');

  return (
    <m.div key="login" {...pageTransitionAnimationProps} ref={ref}>
      <StyledCard>
        {serviceOfferingBasic.isEnabled ? (
          <>
            <Typography>Willkommen bei der {tenant.displayName}.</Typography>
            <Typography>
              Hier bieten wir Ihnen{' '}
              {serviceOfferingBasic.services.length > 1 ? 'folgende Angebote' : 'folgendes Angebot'} an:
            </Typography>
            <ServiceList services={serviceOfferingBasic.services} />
            <Typography>
              Um auf {serviceOfferingBasic.services.length > 1 ? 'die Angebote' : 'das Angebot'} zugreifen zu können,
              loggen Sie sich bitte mit Ihrer Patientennummer (PID) ein. Diese finden Sie auf Ihrem Aufgebot.
            </Typography>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                onLogin(password);
              }}>
              <FormControl error={loginIsError}>
                <FormLabel>Patientennummer</FormLabel>
                <LoginInput
                  required
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  endDecorator={
                    <Button type="submit" loading={loginIsFetching}>
                      Weiter
                    </Button>
                  }
                />
                <FormHelperText>
                  {loginIsError
                    ? 'Patientennummer war nicht korrekt'
                    : loginIsFetching
                      ? 'Ihr Angebot wird geladen...'
                      : 'Bitte geben Sie ihre Patientennummer ein'}
                </FormHelperText>
              </FormControl>
            </form>
          </>
        ) : (
          <Alert color="danger">
            Ihr Termin wurde abgesagt oder verschoben.
            {serviceOfferingBasic.services[0].stateData?.ticketLink &&
              ' Das Ticket, welches Sie bestellt haben, können Sie aber weiterhin herunterladen.'}
          </Alert>
        )}
        {serviceOfferingBasic.services[0].stateData?.ticketLink && (
          <>
            <Divider inset="context" />
            <DownloadTicketButton
              ticketLink={String(serviceOfferingBasic.services[0].stateData.ticketLink)}
              serviceKey={serviceOfferingBasic.services[0].id}
            />
          </>
        )}
      </StyledCard>
    </m.div>
  );
});

const StyledCard = styled(Card)`
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
`;

const LoginInput = styled(Input)`
  --Input-decoratorChildHeight: 45px;
  input {
    width: 40px; // essentially acts as min-width at small screen sizes
  }
  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
