import { styled, Typography } from '@mui/joy';
import { format } from 'date-fns';
import { useContext } from 'react';
import { TenantContext } from '../../../../pages/ServiceOffering';
import { ServiceContext, ServiceOfferingContext } from '../../Services';
import DownloadTicketButton from '../DownloadTicketButton';
import { SBBCard } from '../SBBCard';

const Introduction = styled(Typography)`
  time,
  strong {
    font-weight: 500;
    color: var(--joy-palette-neutral-900);
  }
`;

export function DownloadTicketCard() {
  const tenant = useContext(TenantContext);
  const { dateTime } = useContext(ServiceOfferingContext);
  const { id: serviceKey, stateData } = useContext(ServiceContext);

  return (
    <SBBCard>
      <Introduction>
        Ihr Ticket für den Termin am{' '}
        <time dateTime={dateTime}>{format(new Date(dateTime), "EEEE, do MMMM yyyy 'um' HH:mm")}</time> in der{' '}
        <strong>{tenant.displayName}</strong>.
      </Introduction>
      <Introduction>
        Bitte vergessen Sie nicht, Ihr Ticket für die Reise mitzunehmen. Laden Sie es dazu herunter und speichern Sie es
        auf Ihrem Mobilgerät, das Sie bei sich tragen, oder drucken Sie es aus.
      </Introduction>
      <DownloadTicketButton serviceKey={serviceKey} ticketLink={String(stateData?.ticketLink)} />
    </SBBCard>
  );
}
