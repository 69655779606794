import { DirectionsWalk } from '@mui/icons-material';
import { styled, Typography, typographyClasses } from '@mui/joy';
import { Trip } from '../../../api-client-gen';
import { getDurationString, getNonWalkTripData, getTotalTimeWithWalking } from './utils/tripHelper';

export default function Timetable({ trip, className }: { trip: Trip; className?: string }) {
  const firstOfAllSegments = trip.segments[0];
  const { segments, totalTime } = getNonWalkTripData(trip);
  const totalTimeWithWalking = getTotalTimeWithWalking(trip);

  const departureTrack = firstOfAllSegments.stops[0].departureTrack;
  const direction = segments[0].direction;
  return (
    <Container className={className}>
      <div style={{ gridArea: 'train' }}>
        <Typography fontSize="small">
          <Typography variant="outlined">{segments[0].vehicleShort}</Typography>
          {direction ? `Richtung: ${direction}` : ''}
        </Typography>
      </div>
      <Typography fontWeight="lg" textColor="neutral.900" style={{ gridArea: 'departure' }}>
        {segments[0].departureTime}
      </Typography>
      <TimetablePearlChain>
        <div />
        {segments.map((segment, index) => (
          <div key={index} style={{ width: `${(segment.duration / totalTime) * 100}%` }} />
        ))}
      </TimetablePearlChain>
      <Typography fontWeight="lg" textColor="neutral.900" textAlign="right" style={{ gridArea: 'arrival' }}>
        {segments[segments.length - 1].arrivalTime}
      </Typography>
      <div style={{ gridArea: 'startInfo', alignSelf: 'baseline' }}>
        {firstOfAllSegments.type === 'WALK_TRANSFER' ? (
          <WalkInfo
            color={'warning'}
            startDecorator={
              <DirectionsWalk viewBox="6 0 12 24" style={{ height: '0.9em', width: '0.6em' }} color={'warning'} />
            }
            fontSize="small">
            <span>
              <Typography textColor="warning.600">
                {getDurationString(
                  new Date(firstOfAllSegments.stops[0].departureDateTime ?? ''),
                  new Date(firstOfAllSegments.stops[1].arrivalDateTime ?? ''),
                  true,
                )}
              </Typography>{' '}
              {firstOfAllSegments.stops[1].name}
            </span>
          </WalkInfo>
        ) : (
          departureTrack && (
            <Typography textColor="neutral.900" fontWeight="normal" sx={{ mt: -0.3 }}>
              {departureTrack.startsWith('Gleis') || departureTrack.startsWith('Kante')
                ? departureTrack
                : `Gl. ${departureTrack}`}
            </Typography>
          )
        )}
      </div>
      <Typography className="timeLabel" fontSize="small" style={{ gridArea: 'timeLabel' }}>
        Dauer
      </Typography>
      <Typography
        className="time"
        textColor="neutral.900"
        fontWeight="lg"
        style={{ gridArea: 'time', alignSelf: 'baseline' }}>
        {totalTimeWithWalking}
      </Typography>
    </Container>
  );
}

const Container = styled('div')`
  display: grid;
  grid-template-areas: 'train train train train' 'departure pearl pearl arrival' 'startInfo startInfo time time';
  grid-template-columns: 52px 1fr auto 52px;
  .timeLabel {
    display: none;
  }
  .time {
    text-align: right;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-areas: 'train train train train timeLabel' 'departure pearl arrival . time' 'startInfo startInfo startInfo startInfo .';
    grid-template-columns: 52px 180px 52px 50px 1fr;
    .timeLabel {
      display: block;
    }
    .time {
      text-align: left;
      align-self: baseline;
    }
  }
  gap: 2px 10px;
`;

const TimetablePearlChain = styled('div')`
  grid-area: pearl;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: inherit;
  display: flex;
  div {
    display: flex;
    align-items: center;
    min-width: 15px;
    &:first-of-type {
      min-width: 12px;
    }
    &:last-of-type {
      min-width: 19px;
    }
    &:not(:first-of-type)::before {
      content: '';
      margin-left: -1px;
      margin-right: 2px;
      height: 2px;
      background: var(--custom-palette-pearl);
      width: 100%;
    }
    &::after {
      content: '';
      display: block;
      height: 8px;
      width: 8px;
      border-radius: 1000px;
      background: var(--custom-palette-pearl);
      flex-shrink: 0;
    }
    &:first-of-type,
    &:last-of-type {
      &::after {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

const WalkInfo = styled(Typography)`
  align-items: baseline;
  .${typographyClasses.startDecorator} {
    transform: translateY(3px);
  }
`;
