/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { TenantDefinition } from '../models/TenantDefinition';
export class TenantResourceService {
  /**
   * @param tenantKey
   * @returns TenantDefinition OK
   * @throws ApiError
   */
  public static get(tenantKey: string): CancelablePromise<TenantDefinition> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/mercury-api/{tenantKey}',
      path: {
        tenantKey: tenantKey,
      },
    });
  }
}
