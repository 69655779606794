/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { BasicServiceOffering } from '../models/BasicServiceOffering';
import type { DecryptedServiceOffering } from '../models/DecryptedServiceOffering';
export class ServiceOfferingResourceService {
  /**
   * @param tenantKey
   * @param serviceOfferingKey
   * @returns BasicServiceOffering OK
   * @throws ApiError
   */
  public static getServiceOffering(
    tenantKey: string,
    serviceOfferingKey: string,
  ): CancelablePromise<BasicServiceOffering> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/mercury-api/{tenantKey}/service-offerings/{serviceOfferingKey}',
      path: {
        tenantKey: tenantKey,
        serviceOfferingKey: serviceOfferingKey,
      },
    });
  }
  /**
   * @param tenantKey
   * @param serviceOfferingKey
   * @param password
   * @param key
   * @returns DecryptedServiceOffering OK
   * @throws ApiError
   */
  public static getDecryptedServiceOffering(
    tenantKey: string,
    serviceOfferingKey: string,
    password: string,
    key: string,
  ): CancelablePromise<DecryptedServiceOffering> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/mercury-api/{tenantKey}/service-offerings/{serviceOfferingKey}/data',
      path: {
        tenantKey: tenantKey,
        serviceOfferingKey: serviceOfferingKey,
      },
      query: {
        password: password,
        key: key,
      },
    });
  }
}
