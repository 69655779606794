import { setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { setupSentry } from './sentry';

setDefaultOptions({ locale: de });

setupSentry();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
