import { Train } from '@mui/icons-material';
import { List, ListItem, ListItemDecorator } from '@mui/joy';
import { useContext } from 'react';
import { ServiceDefinition, TenantDefinition } from '../api-client-gen';
import { TenantContext } from '../pages/ServiceOffering';

export function ServiceList({ services }: { services: ServiceDefinition[] }) {
  const tenant = useContext(TenantContext);
  return (
    <List sx={{ p: 0 }}>
      {services.map((service) => {
        const { decorator, text } = getServiceDescription(service.type, tenant);
        return (
          <ListItem sx={{ px: 0 }} key={service.id}>
            <ListItemDecorator>{decorator}</ListItemDecorator>
            {text}
          </ListItem>
        );
      })}
    </List>
  );
}

function getServiceDescription(type: ServiceDefinition['type'], tenant: TenantDefinition) {
  switch (type) {
    case 'SBB':
      return {
        decorator: <Train />,
        text: `Buchen Sie ein kostenloses Ticket für den Transport zur ${tenant.displayName} mit dem öffentlichen Verkehr.`,
      };
  }
}
