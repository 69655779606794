import { Card, styled } from '@mui/joy';
import { sbbColors } from './utils/sbbColors';

export const SBBCard = styled(Card)`
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  justify-self: center;

  ${sbbColors};

  --custom-palette-pearl: var(--joy-palette-neutral-900);
`;
