/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { Booking } from '../models/Booking';
import type { BookTripRequest } from '../models/BookTripRequest';
import type { SbbLocation } from '../models/SbbLocation';
import type { TripPage } from '../models/TripPage';
export class SbbResourceService {
  /**
   * @param tenantKey
   * @param serviceId
   * @param requestBody
   * @returns Booking OK
   * @throws ApiError
   */
  public static bookTrip(
    tenantKey: string,
    serviceId: string,
    requestBody: BookTripRequest,
  ): CancelablePromise<Booking> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mercury-api/{tenantKey}/services/{serviceId}/sbb/book-trip',
      path: {
        tenantKey: tenantKey,
        serviceId: serviceId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param tenantKey
   * @param serviceId
   * @param originId
   * @param dateTime
   * @param scrollContext
   * @returns TripPage OK
   * @throws ApiError
   */
  public static getTrips(
    tenantKey: string,
    serviceId: string,
    originId: number,
    dateTime: string,
    scrollContext?: string,
  ): CancelablePromise<TripPage> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/mercury-api/{tenantKey}/services/{serviceId}/sbb/trips',
      path: {
        tenantKey: tenantKey,
        serviceId: serviceId,
      },
      query: {
        originId: originId,
        dateTime: dateTime,
        scrollContext: scrollContext,
      },
    });
  }
  /**
   * @param tenantKey
   * @param serviceId
   * @param ticketUrl
   * @returns binary default response
   * @throws ApiError
   */
  public static retrieveTicket(tenantKey: string, serviceId: string, ticketUrl: string): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/mercury-api/{tenantKey}/services/{serviceId}/sbb/retrieve-ticket',
      path: {
        tenantKey: tenantKey,
        serviceId: serviceId,
      },
      query: {
        ticketUrl: ticketUrl,
      },
    });
  }
  /**
   * @param tenantKey
   * @param serviceId
   * @param name
   * @returns SbbLocation OK
   * @throws ApiError
   */
  public static getLocation(tenantKey: string, serviceId: string, name: string): CancelablePromise<Array<SbbLocation>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/mercury-api/{tenantKey}/services/{serviceId}/sbb/location',
      path: {
        tenantKey: tenantKey,
        serviceId: serviceId,
      },
      query: {
        name: name,
      },
    });
  }
}
