import { createContext, forwardRef } from 'react';
import { DecryptedServiceOffering, ServiceDefinition } from '../../api-client-gen';
import { SBBService } from './SBBService/SBBService';

const nullServiceOffering = {
  serviceOfferingKey: '',
  dateTime: '',
  data: { birthDate: '', lastName: '', firstName: '', emailAddress: '' },
  services: [],
};
export const ServiceOfferingContext = createContext<DecryptedServiceOffering>(nullServiceOffering);

const nullService = { id: '', data: { returnTicket: false }, type: 'SBB' } as const;
export const ServiceContext = createContext<ServiceDefinition>(nullService);

export const Services = forwardRef<HTMLDivElement, { serviceOffering: DecryptedServiceOffering }>(function Services(
  { serviceOffering },
  ref,
) {
  return (
    <ServiceOfferingContext.Provider value={serviceOffering}>
      {serviceOffering.services.map((service) => (
        <ServiceContext.Provider key={service.id} value={service}>
          {(() => {
            switch (service.type) {
              case 'SBB':
                return <SBBService ref={ref} />;
              default:
                throw new Error(`The service type ${service.type} has not been implemented yet.`);
            }
          })()}
        </ServiceContext.Provider>
      ))}
    </ServiceOfferingContext.Provider>
  );
});
