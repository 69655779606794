import { styled } from '@mui/joy';
import { Route, Routes } from 'react-router-dom';
import Providers from './Providers';
import { chooseTripRoute, confirmTripRoute, downloadTicketRoute } from './components/services/SBBService/SBBService';
import { ChooseTripCard } from './components/services/SBBService/cards/ChooseTripCard';
import { ConfirmTripCard } from './components/services/SBBService/cards/ConfirmTripCard';
import { DownloadTicketCard } from './components/services/SBBService/cards/DownloadTicketCard';
import ServiceOffering from './pages/ServiceOffering';
import { Version } from './pages/Version';

const Root = styled('div')`
  background: ${({ theme }) => theme.palette.background.body};
  min-height: 100vh;
`;

export default function App() {
  return (
    <Providers>
      <Root>
        <Routes>
          <Route path="/:tenantKey/service-offering/:serviceOfferingKey" element={<ServiceOffering />}>
            <Route path={chooseTripRoute} element={<ChooseTripCard />} />
            <Route path={confirmTripRoute} element={<ConfirmTripCard />} />
            <Route path={downloadTicketRoute} element={<DownloadTicketCard />} />
          </Route>
          <Route path="/version" element={<Version />} />
        </Routes>
      </Root>
    </Providers>
  );
}
