import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useDeferredValue, useState } from 'react';
import { SbbLocation, SbbResourceService, TripPage } from '../../../api-client-gen';
import { withCancellationSupport } from '../utils/withCancellationSupport';

const getSBBServiceLocationsQuery = ({
  tenantKey,
  serviceKey,
  name,
}: {
  tenantKey: string;
  serviceKey: string;
  name: string;
}): UseQueryOptions<SbbLocation[]> => ({
  queryKey: [tenantKey, 'service-sbb', serviceKey, 'location', name],
  queryFn: withCancellationSupport(() => SbbResourceService.getLocation(tenantKey, serviceKey, name)),
});

export const useSBBServiceLocationAutocomplete = ({
  tenantKey,
  serviceKey,
  name,
}: {
  tenantKey: string;
  serviceKey: string;
  name: string;
}) => {
  const [cachedLocationOptions, setCachedLocationOptions] = useState<SbbLocation[] | undefined>(undefined);
  const deferredName = useDeferredValue(name);
  const enabled = deferredName.length >= 2;
  if (!enabled && cachedLocationOptions) {
    setCachedLocationOptions(undefined);
  }
  const locationOptions = useQuery({
    ...getSBBServiceLocationsQuery({ tenantKey, serviceKey, name: deferredName }),
    enabled,
    retry: false,
  });
  if (locationOptions.data && cachedLocationOptions !== locationOptions.data) {
    setCachedLocationOptions(locationOptions.data);
  }
  return {
    isError: locationOptions.isError && enabled,
    isLoading: locationOptions.isLoading && enabled,
    // returns the last cached result (happens whenever the name value changes and no cache hit is found for the new query)
    locations: locationOptions.isError ? undefined : (locationOptions.data ?? cachedLocationOptions),
  };
};

export const getSBBServiceTripsQuery = ({
  tenantKey,
  serviceKey,
  originId,
  dateTime,
}: {
  tenantKey: string;
  serviceKey: string;
  originId: number;
  dateTime: string;
}) => ({
  queryKey: [tenantKey, 'service-sbb', serviceKey, 'trips', originId, dateTime],
  queryFn: withCancellationSupport<TripPage, QueryKey, string>(({ pageParam }) =>
    SbbResourceService.getTrips(tenantKey, serviceKey, originId, dateTime, pageParam),
  ),
});

export const postSBBServiceBookRouteMutation = (
  {
    tenantKey,
    serviceKey,
    tripId,
    firstName,
    lastName,
    dateOfBirth,
  }: {
    tenantKey: string;
    serviceKey: string;
    tripId: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
  },
  onSuccess: () => void,
) => ({
  mutationFn: async ({ hasHalfFare, returnTicket }: { hasHalfFare: boolean; returnTicket: boolean }) =>
    await SbbResourceService.bookTrip(tenantKey, serviceKey, {
      tripId: tripId,
      firstName: firstName,
      lastName: lastName,
      birthDate: dateOfBirth,
      halfFare: hasHalfFare,
      returnTicket,
    }),
  onSuccess,
});
